<template>
  <div>
    <div class="home">
      <img class="home_background" src="@/assets/banner-checkout.jpg">
    </div>

    <div class="cart_section">
      <div class="section_container">
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="cart_container">

                <div class="cart_bar">
                  <ul class="cart_bar_list item_list d-flex flex-row align-items-center justify-content-start">
                    <li>Product</li>
                    <li>Price</li>
                    <li>Quantity</li>
                    <li>Total</li>
                  </ul>
                </div>

                <div class="cart_items">
                  <ul class="cart_items_list">
                    <li
                      class="cart_item item_list d-flex flex-lg-row flex-column align-items-lg-center align-items-start justify-content-start"
                      v-for="(item, i) of cart"
                      :key="i"
                    >
                      <div class="cart_product d-flex flex-lg-row flex-column align-items-lg-center align-items-start justify-content-start">
                        <div>
                          <div class="product_image">
                            <img :src="item.images[0].thumbnail_url" alt="">
                          </div>
                        </div>
                        <div class="product_name">
                          <router-link :to="`/product/${getProductSlug(item.canonical)}`">{{ item.name }}</router-link>
                        </div>
                      </div>
                      <div class="product_price text-lg-center product_text">
                        <span>Price: </span>
                        <div>${{ item.discount_price ? item.discount_price : item.regular_price }}</div>
                        <div v-if="item.discount_price">
                          <strike class="text-danger">${{ item.regular_price }}</strike>
                        </div>
                      </div>
                      <div class="product_quantity_container">
                        <div class="product_quantity ml-lg-auto mr-lg-auto text-center">
                          <span class="product_text product_num">{{ item.qty }}</span>
                          <div
                            class="qty_sub qty_button trans_200 text-center"
                            @click="decrement(item)"
                          >
                            <span>-</span>
                          </div>
                          <div
                            class="qty_add qty_button trans_200 text-center"
                            @click="increment(item)"
                          >
                            <span>+</span>
                          </div>
                        </div>
                      </div>
                      <div class="product_total text-lg-center product_text">
                        <span>Total: </span>${{ getItemTotalPrice(item) }}
                      </div>
                    </li>
                  </ul>

                  <div
                    class="cart_items_empty"
                    v-if="!cart.length"
                  >
                    Your Cart is Empty
                  </div>
                </div>

                <div class="cart_buttons d-flex flex-row align-items-start justify-content-start">
                  <div class="cart_buttons_inner ml-auto d-flex flex-row align-items-start justify-content-start flex-wrap">
                    <div class="button button_continue trans_200">
                      <router-link to="/">continue shopping</router-link>
                    </div>
                    <div class="button button_clear trans_200">
                      <a href="" @click.prevent="clearCart">clear cart</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section_container cart_extra_container">
        <div class="container">
          <div class="row">
            <div class="col-xxl-6"></div>
            <div class="col-xxl-6">
              <div class="cart_extra cart_extra_2">
                <div class="cart_extra_content cart_extra_total">
                  <div class="cart_extra_title d-flex">
                    Subtotal
                    <span class="ml-auto">${{ cartPrice }}</span>
                  </div>
                  <div class="checkout_button trans_200" :class="{ 'button-disabled': isCartEmpty }">
                    <a v-if="isCartEmpty">proceed to checkout</a>
                    <router-link
                      to="/checkout"
                      v-else
                    >
                      proceed to checkout
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'cart',

  computed: {
    cart() {
      return this.$store.state.cart;
    },
    cartPrice() {
      return this.$store.state.cartPrice;
    },
    cartShipping() {
      return this.$store.state.cartShipping;
    },
    isCartEmpty() {
      return !this.cart.length;
    },
  },

  methods: {
    decrement(item) {
      this.$store.commit('DECREMENT_CART_ITEM', item);
    },
    increment(item) {
      this.$store.commit('INCREMENT_CART_ITEM', item);
    },
    getItemTotalPrice(item) {
      const productPrice = item.discount_price
        ? item.discount_price
        : item.regular_price;
      return parseFloat((item.qty * productPrice).toFixed(2));
    },
    clearCart() {
      this.$store.commit('CLEAR_CART');
    },
    getProductSlug(canonical) {
      const parts = canonical.split('/');
      return parts[parts.length - 1];
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/views/cart.scss';
</style>
